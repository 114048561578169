import React, { useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Folder,
  MoreVert,
  OpenInBrowser,
  Download,
  Edit,
  Share,
  Delete,
  Info,
} from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useTheme } from "../../style/theme";

const TileFilesFolder = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { mode } = useTheme();

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(6, 1fr)"
      gap="1rem"
      padding="1rem"
    >
      {items.map((item, index) => (
        <Box
          key={index}
          padding="1rem"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="15rem"
          height="15rem"
          sx={{
            // backgroundColor: "#1E1E1E",
            backgroundColor: mode === "light" ? "white" : "#1E1E1E",
            border: `2px solid ${mode === "light" ? "#e5e7eb" : "transparent"}`,
            borderRadius: "0.5rem",
            position: "relative",
            // "&:hover": {
            //   cursor: "pointer",
            //   backgroundColor: "#2A2A2A",
            // },
            ":hover": {
              backgroundColor: mode === "light" ? "#f7f7f7" : "#2E2E2E",
            },
          }}
        >
          <Box width="100%" display="flex" justifyContent="end">
            <Tooltip title="More Actions" arrow>
              <IconButton
                onClick={(e) => handleClick(e)}
                style={{ fontSize: 30, color: "#A5A5A5", cursor: "pointer" }}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          </Box>

          {item.clikkle && item.type === "folder" && (
            <Folder style={{ fontSize: 150, color: "#488BF1" }} />
          )}
          {!item.clikkle && item.type === "folder" && (
            <Folder style={{ fontSize: 150, color: "#FFCA28" }} />
          )}

          {item.type === "file" && (
            <InsertDriveFileIcon style={{ fontSize: 150, color: "#488BF1" }} />
          )}

          <Typography
            variant="body"
            fontFamily="sans-serif"
            fontWeight="500"
            lineHeight="2rem"
            // sx={{ color: "#FFF" }}
            sx={{
              color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
            }}
          >
            {item.name}
          </Typography>

          {/* Menu for showing the actions */}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={handleClose}>
              <OpenInBrowser
                style={{ color: "#488BF1", marginRight: "10px" }}
              />
              Open with
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Download style={{ color: "#FFCA28", marginRight: "10px" }} />
              Download
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Edit style={{ color: "#FF5722", marginRight: "10px" }} />
              Rename
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Share style={{ color: "#4CAF50", marginRight: "10px" }} />
              Share
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Info style={{ color: "#9C27B0", marginRight: "10px" }} />
              Folder Information
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Delete style={{ color: "#F44336", marginRight: "10px" }} />
              Move to Bin
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Info style={{ color: "#607D8B", marginRight: "10px" }} />
              Not a Helpful Suggestion
            </MenuItem>
          </Menu>
        </Box>
      ))}
    </Box>
  );
};

export default TileFilesFolder;
