import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import RecentTypeDropdown from "../components/RecentDropdowns/TypeDropdown";
import RecentPeopleDropdown from "../components/RecentDropdowns/PeopleDropdown";
import RecentModifiedDropdown from "../components/RecentDropdowns/ModifiedDropdown";
import RecentSourceDropdown from "../components/RecentDropdowns/SourceDropdown";
import RecentView from "../components/Recent/RecentFilesAndFoldersView";
import FloatingActionButton from "../components/ActionButton/FloatingActionButton";
import { useTheme } from "../style/theme";
const Recent = () => {

  const {mode} =useTheme();
  return (
    <Box p={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap="1rem"
          alignItems="flex-start"
        >
          {/* Title aligned to the left */}
          <Typography
            fontSize="2rem"
            sx={{
              color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
              fontWeight: "bold",
              // color: "#f5f5f5",
              padding: "0.5rem 1rem",
            }}
          >
            Recent
          </Typography>

          <Box display="flex" gap="1rem" justifyContent="center">

            {/*  Dropdown Components */}

            <RecentTypeDropdown />

            <RecentPeopleDropdown />

            <RecentModifiedDropdown />

            <RecentSourceDropdown />


            {/* Action button */}
            <FloatingActionButton />


          </Box>
        </Box>
      </Grid>
      <RecentView />

    </Box>

  );
};

export default Recent;
