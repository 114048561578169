import React, { useState } from "react";
import { Box, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import {
  Folder,
  MoreVert,
  OpenInBrowser,
  Download,
  Edit,
  Share,
  Delete,
  Info,
  ThumbDown,
} from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useTheme } from "../../style/theme";

const RecentGridView = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const folders = items.filter((item) => item.type === "folder");
  const files = items.filter((item) => item.type === "file");

  const { mode } = useTheme();



  return (
    <Box padding="1rem">
      {/* Folders Section */}
      <Typography
        variant="h6"
        fontWeight="bold"
        fontFamily="sans-serif"
        // sx={{ color: "#FFF", marginBottom: "1rem" }}
        sx={{
          marginBottom: "1rem",
          color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
        }}
      >
        Folders
      </Typography>
      <Box display="grid" gridTemplateColumns="repeat(6, 1fr)" gap="1rem">
        {folders.map((folder, index) => (
          <Box
            key={index}
            padding="1rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="23rem"
            height="6rem"
            sx={{
              // backgroundColor: "#1E1E1E",
              backgroundColor: mode === "light" ? "#F7F9FC" : "#1E1E1E",
              border: `2px solid ${
                mode === "light" ? "#e5e7eb" : "transparent"
              }`,
              ":hover": {
                backgroundColor: mode === "light" ? "#f7f7f7" : "#2E2E2E",
              },
              borderRadius: "0.5rem",
              position: "relative",
              // "&:hover": {
              //   cursor: "pointer",
              //   backgroundColor: "#2A2A2A",
              // },
            }}
          >
            <Folder
              style={{
                fontSize: 60,
                color: folder.clikkle ? "#488BF1" : "#FFCA28",
              }}
            />
            <Typography
              variant="body2"
              fontFamily="sans-serif"
              fontWeight="500"
              textAlign="center"
              // sx={{ color: "#FFF", marginTop: "0.5rem" }}
              sx={{
                color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
              }}
            >
              {folder.name}
            </Typography>
            <IconButton
              onClick={handleClick}
              sx={{
                color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,

                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
                // color: "#FFF",
              }}
            >
              <MoreVert />
            </IconButton>
          </Box>
        ))}
      </Box>

      {/* Files Section */}
      <Typography
        variant="h6"
        fontWeight="bold"
        fontFamily="sans-serif"
        // sx={{ color: "#FFF", margin: "2rem 0 1rem 0" }}
        sx={{
          margin: "2rem 0 1rem 0",
          color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
        }}
      >
        Files
      </Typography>
      <Box display="grid" gridTemplateColumns="repeat(6, 1fr)" gap="1rem">
        {files.map((file, index) => (
          <Box
            key={index}
            padding="1rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="15rem"
            height="15rem"
            sx={{
              // backgroundColor: "#1E1E1E",
              backgroundColor: mode === "light" ? "#F7F9FC" : "#1E1E1E",
              border: `2px solid ${
                mode === "light" ? "#e5e7eb" : "transparent"
              }`,
              ":hover": {
                backgroundColor: mode === "light" ? "#f7f7f7" : "#2E2E2E",
              },
              borderRadius: "0.5rem",
              position: "relative",
              // "&:hover": {
              //   cursor: "pointer",
              //   backgroundColor: "#2A2A2A",
              // },
            }}
          >
            <InsertDriveFileIcon style={{ fontSize: 190, color: "#488BF1" }} />
            <Typography
              variant="body2"
              fontFamily="sans-serif"
              fontWeight="500"
              textAlign="center"
              // sx={{ color: "#FFF", marginTop: "0.5rem" }}
              sx={{
                // color: "white",
                fontSize: "13px",
                color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
              }}
            >
              {file.name}
            </Typography>
            <IconButton
              onClick={handleClick}
              sx={{
                color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,

                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
                // color: "#FFF",
              }}
            >
              <MoreVert />
            </IconButton>
          </Box>
        ))}
      </Box>

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleClose}>
          <OpenInBrowser style={{ marginRight: "0.5rem", color: "#4CAF50" }} />{" "}
          Open
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Download style={{ marginRight: "0.5rem", color: "#03A9F4" }} />{" "}
          Download
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Share style={{ marginRight: "0.5rem", color: "#FF9800" }} /> Share
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Edit style={{ marginRight: "0.5rem", color: "#FFC107" }} /> Rename
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Info style={{ marginRight: "0.5rem", color: "#9C27B0" }} /> Details
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Delete style={{ marginRight: "0.5rem", color: "#F44336" }} /> Move to
          Bin
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ThumbDown style={{ marginRight: "0.5rem", color: "#607D8B" }} /> Not
          a Helpful Suggestion
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default RecentGridView;
