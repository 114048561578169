import React, { useState } from "react";
import { Box, Typography, Tooltip, Menu, MenuItem, ListItemIcon } from "@mui/material";
import { Folder, MoreVert, OpenInNew, Download, Edit, Share } from "@mui/icons-material";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial"; 
import FolderOpenIcon from "@mui/icons-material/FolderOpen"; 
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"; 
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt"; 
import {useTheme} from "../../style/theme"

const FolderList = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHoveredDots, setIsHoveredDots] = useState(false); // State to track if the three dots are hovered

  // Handle menu open
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle menu close
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle hover on three dots
  const handleDotsHover = (status) => {
    setIsHoveredDots(status);
  };

  const {mode}=useTheme();

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(5, 1fr)" // 6 columns grid layout
      gap="0.5rem" // Space between grid items
      padding="1rem" // Padding around the grid
    >
      {items.map((item, index) => (
        // Conditional Tooltip based on the hover status of the three dots
        <Tooltip 
          title={!isHoveredDots ? item.name : ""} 
          placement="top" 
          arrow 
          key={index}
        >
          <Box
            padding="1rem"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="19rem"
            height="5rem"
            sx={{
              // backgroundColor: "#1E1E1E",
              backgroundColor: mode === 'light'? '#F7F9FC': "#1E1E1E",
              border: `2px solid ${mode === 'light'? '#e5e7eb':'transparent'}`,
              ":hover" : {
              backgroundColor: mode === 'light' ? '#f7f7f7' : "#2E2E2E"
            },
              borderRadius: "0.5rem",
              // "&:hover": {
              //   backgroundColor: "#2E2E2E", // Hover effect for background color
              // },
              cursor: "pointer", // Make the whole box clickable
            }}
          >
            {/* Left side: Folder icon and item details */}
            <Box width="100%" display="flex" gap="1rem" alignItems="center">
              {/* Display different folder icons based on item condition */}
              {item.clikkle ? (
                <Folder style={{ fontSize: "1.5rem", color: "#488BF1" }} />
              ) : (
                <Folder style={{ fontSize: "1.5rem", color: "#FFCA28" }} />
              )}

              <Box display="flex" gap="0rem" flexDirection="column">
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="500"
                  sx={{
                    // color: "white",
                    fontSize: "13px",
                     color: `${mode === 'light'? 'rgba(0, 0, 0, 0.6)': '#fff'} ` }}
                
                >
                  {item.name}
                </Typography>

                {/* Always show location */}
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="500"
                  sx={{
                    // color: "white",
                    fontSize: "11px",
                     color: `${mode === 'light'? 'rgba(0, 0, 0, 0.6)': '#fff'} ` 
                  }}
                >
                  {item.location}
                </Typography>
              </Box>
            </Box>

            {/* More options menu */}
            <Tooltip title="More actions" placement="top" arrow>
              <MoreVert
                onClick={(event) => handleMenuOpen(event)} // Open menu on click
                onMouseEnter={() => handleDotsHover(true)} // Set isHoveredDots to true when hovering over three dots
                onMouseLeave={() => handleDotsHover(false)} // Set isHoveredDots to false when not hovering
                style={{
                  fontSize: 30,
                  color: "#A5A5A5", // Default color for the icon
                  cursor: "pointer",
                }}
              />
            </Tooltip>
          </Box>
        </Tooltip>
      ))}

      {/* Menu for the selected item */}
      <Menu
        anchorEl={anchorEl} // Anchor element for positioning
        open={Boolean(anchorEl)} // Open if anchorEl is set
        onClose={handleMenuClose} // Close on menu close
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* Menu items with icons */}
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <OpenInNew fontSize="small" style={{ color: "#4CAF50" }} />
          </ListItemIcon>
          Open with
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <Download fontSize="small" style={{ color: "#2196F3" }} />
          </ListItemIcon>
          Download
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <Edit fontSize="small" style={{ color: "#FFC107" }} />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <Share fontSize="small" style={{ color: "#00BCD4" }} />
          </ListItemIcon>
          Share
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <FolderSpecialIcon fontSize="small" style={{ color: "#9C27B0" }} />
          </ListItemIcon>
          Organise
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <FolderOpenIcon fontSize="small" style={{ color: "#FFC107" }} />
          </ListItemIcon>
          Folder Information
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" style={{ color: "#F44336" }} />
          </ListItemIcon>
          Move to bin
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
            <ThumbDownAltIcon fontSize="small" style={{ color: "#E91E63" }} />
          </ListItemIcon>
          Not a Helpful Suggestion
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default FolderList;









