import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import FolderIcon from "@mui/icons-material/Folder";
import GroupIcon from "@mui/icons-material/Group";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const LocationDropdown = () => {
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  return (
    <FormControl sx={{ width: "10rem" }}>
      <InputLabel id="location-dialog">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <PlaceIcon fontSize="small" />
          Location
        </Box>
      </InputLabel>

      <Select
        labelId="location-dialog"
        label="Location"
        value={selectedLocation}
        onChange={handleChange}
        sx={{ borderRadius: "20rem" }}
      >
        <MenuItem value="anywhere">
          <Box display="flex" alignItems="center" gap="0.5rem">
            <Typography sx={{ fontWeight: "bold" }}>✔</Typography>
            Anywhere in Drive
          </Box>
        </MenuItem>
        <MenuItem value="myDrive">
          <Box display="flex" alignItems="center" gap="0.5rem">
            <FolderIcon sx={{ color: "#4285F4" }} fontSize="small" />
            My Drive
          </Box>
        </MenuItem>
        <MenuItem value="shared">
          <Box display="flex" alignItems="center" gap="0.5rem">
            <GroupIcon sx={{ color: "#34A853" }} fontSize="small" />
            Shared with me
          </Box>
        </MenuItem>
        <MenuItem value="starred">
          <Box display="flex" alignItems="center" gap="0.5rem">
            <StarBorderIcon sx={{ color: "#FBBC05" }} fontSize="small" />
            Starred
          </Box>
        </MenuItem>
        <MenuItem value="binned">
          <Box display="flex" alignItems="center" gap="0.5rem">
            <DeleteOutlineIcon sx={{ color: "#EA4335" }} fontSize="small" />
            Binned
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem value="custom">
          <Box display="flex" alignItems="center" gap="0.5rem">
            Custom location &gt;
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default LocationDropdown;
