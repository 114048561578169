import React, { useState } from "react";
import {
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PersonIcon from "@mui/icons-material/Person";
import { SiReasonstudios } from "react-icons/si";
import FolderIcon from "@mui/icons-material/Folder";
import ListFilesFolder from "./FilesList";
import TileFilesFolder from "../SuggestFolders/GridViewFolder";
import { RandomClikkleItems } from "../Constants/DummyData";

const SuggestedFile = () => {
  const [suggestedFile, setSuggestedFile] = useState(true);
  const [view, setView] = useState("list");

  const handleViewChange = (event, newView) => {
    if (newView !== null) setView(newView);
  };

  return (
    <Box
      display="absolute"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      <Button
        startIcon={suggestedFile ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        onClick={() => setSuggestedFile(!suggestedFile)}
      >
        Suggested File
      </Button>

      {suggestedFile && (
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            alignItems="end"
            width="100%"
            gap="1rem"
          >
            <ToggleButtonGroup
              value={view}
              exclusive
              onChange={handleViewChange}
              aria-label="view selection"
            >
              <ToggleButton
                value="list"
                aria-label="list view"
                sx={{
                  color: "#3767B1",
                  paddingX: "1rem",
                  border: "2px solid #3767B1",
                  borderRadius: "20rem",
                  "&.Mui-selected": {
                    color: "white",
                    backgroundColor: "#3767B1",
                  },
                }}
              >
                <ListIcon fontSize="1rem" />
              </ToggleButton>
              <ToggleButton
                value="folder"
                aria-label="folder view"
                sx={{
                  color: "#3767B1",
                  paddingX: "1rem",
                  border: "2px solidrgb(57, 108, 185)",
                  borderRadius: "20rem",
                  "&.Mui-selected": {
                    color: "white",
                    backgroundColor: "#3767B1",
                  },
                }}
              >
                {view === "folder" ? (
                  <GridViewRoundedIcon fontSize="1rem" />
                ) : (
                  <GridViewIcon fontSize="1rem" />
                )}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
      )}

      {/* File View Sections */}
      {suggestedFile && view === "list" && (
        <Box
          display="flex"
          width="100%"
          sx={{ maxHeight: "calc(100vh - 28rem)", overflowY: "auto" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            sx={{
              width: "100%",
              maxHeight: "82vh",
              overflowY: "auto",
              paddingTop: "1rem",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {/* Header Row with Icons */}
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              paddingY="1rem"
              sx={{ color: "#646464", borderBottom: "1px solid #646464" }}
            >
              <Box width="25%" display="flex" alignItems="center" gap="0.5rem">
                <FolderIcon fontSize="small" />
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                >
                  Name
                </Typography>
              </Box>
              <Box width="25%" display="flex" alignItems="center" gap="0.5rem">
              <SiReasonstudios fontSize="medium" />
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                >
                  Reason Suggested
                </Typography>
              </Box>
              <Box width="25%" display="flex" alignItems="center" gap="0.5rem">
                <PersonIcon fontSize="small" />
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                >
                  Owner
                </Typography>
              </Box>
              <Box width="25%" display="flex" alignItems="center" gap="0.5rem">
              <FolderIcon fontSize="small" />
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                >
                  Location
                </Typography>
              </Box>
              <Box width="5%" display="flex" alignItems="center" gap="0.5rem">
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                ></Typography>
              </Box>
            </Box>
            <ListFilesFolder items={RandomClikkleItems} />
          </Box>
        </Box>
      )}

      {suggestedFile && view === "folder" && (
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          sx={{
            width: "100%",
            paddingTop: "1rem",
            maxHeight: "calc(100vh - 28rem)",
            overflowY: "auto",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <TileFilesFolder items={RandomClikkleItems} />
        </Box>
      )}
    </Box>
  );
};

export default SuggestedFile;
