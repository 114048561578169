import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import FavoriteSourceDropdown from "../components/FavoriteDropdown/SourceDropdown";
import FavoriteModifiedDropdown from "../components/FavoriteDropdown/ModifiedDropdown";
import FavoritePeopleDropdown from "../components/FavoriteDropdown/PeopleDropdown";
import FavoriteTypeDropdown from "../components/FavoriteDropdown/TypeDropdown";
import FavoriteView from "../components/Favorite/FavoriteFilesAndFoldersView";
import FloatingActionButton from "../components/ActionButton/FloatingActionButton";
import { useTheme } from "../style/theme";

const Favorite = () => {
  const { mode } = useTheme();

  return (
    <Box p={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap="1rem"
          alignItems="flex-start"
        >
          {/* Title aligned to the left */}
          <Typography
            fontSize="2rem"
            sx={{
              color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
              fontWeight: "bold",
              // color: "#f5f5f5",
              padding: "0.5rem 1rem",
            }}
            
          >
            Favorite
          </Typography>

          <Box display="flex" gap="1rem" justifyContent="center">
            {/*  Dropdown Components */}

            <FavoriteTypeDropdown />

            <FavoritePeopleDropdown />

            <FavoriteModifiedDropdown />

            <FavoriteSourceDropdown />

            {/* action button */}
            <FloatingActionButton />
          </Box>
        </Box>
      </Grid>
      <FavoriteView />
    </Box>
  );
};

export default Favorite;
