import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import DescriptionIcon from "@mui/icons-material/Description";
import TableChartIcon from "@mui/icons-material/TableChart";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MovieIcon from "@mui/icons-material/Movie";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import FolderIcon from "@mui/icons-material/Folder";
import PublicIcon from "@mui/icons-material/Public";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import BrushIcon from "@mui/icons-material/Brush";
import ArchiveIcon from "@mui/icons-material/Archive";

const TypeDropdown = () => {
  return (
    <FormControl sx={{ width: "10rem" }}>
      <InputLabel id="type-dialog">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <CategoryIcon fontSize="small" />
          Type
        </Box>
      </InputLabel>
      <Select labelId="type-dialog" label="Type" sx={{ borderRadius: "20rem" }}>
        <MenuItem value={10}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <DescriptionIcon sx={{ color: "#4285F4" }} fontSize="small" />
            Documents
          </Box>
        </MenuItem>
        <MenuItem value={20}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <TableChartIcon sx={{ color: "#34A853" }} fontSize="small" />
            Spreadsheets
          </Box>
        </MenuItem>
        <MenuItem value={30}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <SlideshowIcon sx={{ color: "#FBBC05" }} fontSize="small" />
            Presentations
          </Box>
        </MenuItem>
        <MenuItem value={40}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <AssignmentIcon sx={{ color: "#673AB7" }} fontSize="small" />
            Forms
          </Box>
        </MenuItem>
        <MenuItem value={50}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <ImageIcon sx={{ color: "#EA4335" }} fontSize="small" />
            Photos & Images
          </Box>
        </MenuItem>
        <MenuItem value={60}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <PictureAsPdfIcon sx={{ color: "#EA4335" }} fontSize="small" />
            PDFs
          </Box>
        </MenuItem>
        <MenuItem value={70}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <MovieIcon sx={{ color: "#EA4335" }} fontSize="small" />
            Videos
          </Box>
        </MenuItem>
        <MenuItem value={80}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <ShortcutIcon sx={{ color: "#5F6368" }} fontSize="small" />
            Shortcuts
          </Box>
        </MenuItem>
        <MenuItem value={90}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <FolderIcon sx={{ color: "#5F6368" }} fontSize="small" />
            Folders
          </Box>
        </MenuItem>
        <MenuItem value={100}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <PublicIcon sx={{ color: "#4285F4" }} fontSize="small" />
            Sites
          </Box>
        </MenuItem>
        <MenuItem value={110}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <AudiotrackIcon sx={{ color: "#EA4335" }} fontSize="small" />
            Audio
          </Box>
        </MenuItem>
        <MenuItem value={120}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <BrushIcon sx={{ color: "#FBBC05" }} fontSize="small" />
            Drawings
          </Box>
        </MenuItem>
        <MenuItem value={130}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <ArchiveIcon sx={{ color: "#5F6368" }} fontSize="small" />
            Archives (zip)
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default TypeDropdown;
