import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";

const peopleData = [
  {
    id: 1,
    name: "Rakesh Kumar (me)",
    email: "rakeshkum17253@gmail.com",
    avatar: "R",  
    color: "#4285F4", 
  },
  {
    id: 2,
    name: "rakeshkumar233112",
    email: "rakeshkumar233112@gmail.com",
    avatar: "R",  
    color: "#34A853", 
  },
  {
    id: 3,
    name: "Team Recrui91",
    email: "team@recruit91.com",
    avatar: "T",  
    color: "#FBBC05", 
  },
  {
    id: 4,
    name: "Anurag Kurle",
    email: "anuragkurle27@gmail.com",
    avatar: "A",  
    color: "#EA4335", 
  },
];

const SharedByMePeopleDropdown = ({ selectedPerson, setSelectedPerson }) => {
  const [searchText, setSearchText] = useState("");

  const filteredPeople = peopleData.filter(
    (person) =>
      person.name.toLowerCase().includes(searchText.toLowerCase()) ||
      person.email.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <FormControl sx={{ width: "10rem" }}>
      <InputLabel id="people-dialog">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <PersonIcon fontSize="small" />
          People
        </Box>
      </InputLabel>

      <Select
        labelId="people-dialog"
        value={selectedPerson}
        onChange={(e) => setSelectedPerson(e.target.value)}
        label="People"
        sx={{ borderRadius: "20rem" }}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 300 },
          },
        }}
      >
        {/* Search Bar */}
        <Box px={2} pb={1}>
          <TextField
            placeholder="Search for people"
            fullWidth
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Filtered People */}
        {filteredPeople.map((person) => (
          <MenuItem key={person.id} value={person.email}>
            <Box display="flex" alignItems="center" gap="0.8rem">
              <Avatar
                sx={{
                  bgcolor: person.color,  
                  color: "#fff",           
                }}
              >
                {person.avatar}  
              </Avatar>
              <Box>
                <Typography variant="body1">{person.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {person.email}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}

        {/* No Results Found */}
        {filteredPeople.length === 0 && (
          <Box px={2} py={1}>
            <Typography color="textSecondary" align="center">
              No results found
            </Typography>
          </Box>
        )}
      </Select>
    </FormControl>
  );
};

export default SharedByMePeopleDropdown;
