import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

const SharedByMeModifiedDropdown = () => {
  return (
    <FormControl sx={{ width: "10rem" }}>
      <InputLabel id="modified-label">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <DateRangeIcon fontSize="small" />
          Modified
        </Box>
      </InputLabel>
      <Select
        labelId="modified-label"
        label="Modified"
        sx={{ borderRadius: "20rem" }}
      >
        <MenuItem value="today">Today</MenuItem>
        <MenuItem value="last7">Last 7 days</MenuItem>
        <MenuItem value="last30">Last 30 days</MenuItem>
        <MenuItem value="thisYear">This year (2024)</MenuItem>
        <MenuItem value="lastYear">Last year (2023)</MenuItem>
        <Divider />
        <MenuItem value="custom">
          <Box display="flex" alignItems="center" gap="0.5rem">
            Custom date range &gt;
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SharedByMeModifiedDropdown;
