export const NonClikkleItems = [
    {
        type: 'folder',
        name: 'Research Papers',
        location: '/research',
        owner: 'Me',
        size: '1GB',
        reason: 'Academic Papers',
        clikkle: false,
        date: '10/20/2024'
    },
    {
        type: 'folder',
        name: 'Client Presentations',
        location: '/clients/presentations',
        owner: 'Me',
        size: '800MB',
        reason: 'Client Slides',
        clikkle: false,
        date: '09/12/2024'
    },
    {
        type: 'file',
        name: 'Budget Report',
        location: '/finance/budget',
        owner: 'Me',
        size: '450KB',
        reason: 'Budget Report',
        clikkle: false,
        date: '08/15/2024'
    },
    {
        type: 'folder',
        name: 'Product Launch Plans',
        location: '/projects/launch',
        owner: 'Me',
        size: '2GB',
        reason: 'Launch Docs',
        clikkle: false,
        date: '07/30/2024'
    },
    {
        type: 'folder',
        name: 'Legal Documents',
        location: '/legal',
        owner: 'Me',
        size: '750MB',
        reason: 'Legal Docs',
        clikkle: false,
        date: '06/25/2024'
    },
    {
        type: 'file',
        name: 'Annual Report 2023',
        location: '/corporate/reports',
        owner: 'Me',
        size: '2.5MB',
        reason: 'Annual Report',
        clikkle: false,
        date: '05/18/2024'
    },
    {
        type: 'folder',
        name: 'Marketing Strategy 2024',
        location: '/marketing/strategy',
        owner: 'Me',
        size: '1.2GB',
        reason: 'Marketing Plan',
        clikkle: false,
        date: '04/10/2024'
    },
    {
        type: 'folder',
        name: 'HR Policies',
        location: '/hr/policies',
        owner: 'Me',
        size: '300MB',
        reason: 'HR Policies',
        clikkle: false,
        date: '03/25/2024'
    },
    {
        type: 'file',
        name: 'Employee Benefits',
        location: '/hr/benefits',
        owner: 'Me',
        size: '450KB',
        reason: 'Employee Perks',
        clikkle: false,
        date: '02/18/2024'
    },
    {
        type: 'folder',
        name: 'Customer Feedback',
        location: '/customers/feedback',
        owner: 'Me',
        size: '1GB',
        reason: 'Customer Feedback',
        clikkle: false,
        date: '01/30/2024'
    }
];

export const RandomClikkleItems = [
    
        {
            type: "file",
            name: "Employee Handbook",
            location: "/My Drive",
            owner: "Me",
            size: "300KB",
            reason: "You created · 24 Dec · 2024",
            clikkle: false,
            date: "05/10/2024"
        },
        {
            type: "file",
            name: "Quarterly Review",
            location: "/My Drive",
            owner: "Me",
            size: "550KB",
            reason: "You created · 15 Apr · 2024",
            clikkle: false,
            date: "04/15/2024"
        },
        {
            type: "file",
            name: "System Architecture",
            location: "/Shared with me",
            owner: "Me",
            size: "1.2MB",
            reason: "You created · 20 Mar · 2024",
            clikkle: false,
            date: "03/20/2024"
        },
        {
            type: "file",
            name: "User Feedback",
            location: "/My Drive",
            owner: "Me",
            size: "600KB",
            reason: "You created · 18 Feb · 2024",
            clikkle: false,
            date: "02/18/2024"
        },
        {
            type: "file",
            name: "Employee Payroll",
            location: "/Shared with me",
            owner: "Me",
            size: "500KB",
            reason: "You created · 25 Jan · 2024",
            clikkle: false,
            date: "01/25/2024"
        },
        {
            type: "file",
            name: "Marketing Proposal",
            location: "/My Drive",
            owner: "Me",
            size: "1MB",
            reason: "You created · 15 Dec · 2023",
            clikkle: false,
            date: "12/15/2023"
        },
        {
            type: "folder",
            name: "IT Infrastructure Docs",
            location: "/Shared with me",
            owner: "Me",
            size: "2GB",
            reason: "You created · 05 Nov · 2023",
            clikkle: false,
            date: "11/05/2023"
        },
        {
            type: "file",
            name: "Employee Training",
            location: "/My Drive",
            owner: "Me",
            size: "1.5MB",
            reason: "You created · 28 Oct · 2023",
            clikkle: false,
            date: "10/28/2023"
        },
        {
            type: "folder",
            name: "Project Roadmaps",
            location: "/projects/roadmaps",
            owner: "Me",
            size: "3GB",
            reason: "You created · 14 Sep · 2023",
            clikkle: false,
            date: "09/14/2023"
        },
        {
            type: "file",
            name: "Employee Satisfaction",
            location: "/hr/satisfaction",
            owner: "Me",
            size: "800KB",
            reason: "You created · 10 Aug · 2023",
            clikkle: false,
            date: "08/10/2023"
        }
    
];

export const ClikkleItems = [
    {
        type: 'folder',
        name: 'Clikkle DevOps',
        location: 'In My Drive',
        owner: 'Me',
        size: '150MB',
        reason: 'CI/CD Tools',
        clikkle: true,
        date: '01/10/2024'
    },
    {
        type: 'folder',
        name: 'Clikkle UI Designs',
        location: 'In My Drive',
        owner: 'Me',
        size: '300MB',
        reason: 'UI Assets',
        clikkle: true,
        date: '12/05/2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Content Strategy',
        location: 'In My Drive',
        owner: 'Me',
        size: '200MB',
        reason: 'Content Plan',
        clikkle: true,
        date: '11/15/2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Server Configurations',
        location: 'In Shared with me',
        owner: 'Me',
        size: '100MB',
        reason: 'Server Configs',
        clikkle: true,
        date: '10/01/2023'
    },
    {
        type: 'file',
        name: 'Clikkle Vision Document',
        location: 'In My Drive',
        owner: 'Me',
        size: '1MB',
        reason: 'Vision Doc',
        clikkle: true,
        date: '09/10/2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Marketing Campaigns',
        location: 'In My Drive',
        owner: 'Me',
        size: '500MB',
        reason: 'Marketing Assets',
        clikkle: true,
        date: '08/22/2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Customer Support',
        location: 'In Shared with me',
        owner: 'Me',
        size: '350MB',
        reason: 'Support Docs',
        clikkle: true,
        date: '07/18/2023'
    },
    {
        type: 'file',
        name: 'Clikkle Launch Presentation',
        location: 'In My Drive',
        owner: 'Me',
        size: '2.5MB',
        reason: 'Launch Slides',
        clikkle: true,
        date: '06/12/2023'
    },
    {
        type: 'file',
        name: 'Clikkle Quarterly Review',
        location: 'In My Drive',
        owner: 'Me',
        size: '1.8MB',
        reason: 'Q1 Review',
        clikkle: true,
        date: '05/25/2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Product Specifications',
        location: 'In Shared with me',
        owner: 'Me',
        size: '900MB',
        reason: 'Product Specs',
        clikkle: true,
        date: '04/30/2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Books',
        location: 'In My Drive',
        owner: 'Me',
        size: '150MB',
        reason: 'CI/CD Tools',
        clikkle: true,
        date: '01/10/2024'
    },
    {
        type: 'folder',
        name: 'Clikkle Store Designs',
        location: 'In My Drive',
        owner: 'Me',
        size: '300MB',
        reason: 'UI Assets',
        clikkle: true,
        date: '12/05/2023'
    },
];
