export const NonClikkleItems = [
    {
        type: 'folder',
        name: 'Upload solution',
        location: 'My Drive',
        owner: 'farman.maiik@pwlive',
        size: '1GB',
        reason: 'Contains academic research papers',
        clikkle: false,
        date: '17 Oct 2023'
    },
    {
        type: 'folder',
        name: 'Upload solution',
        location: 'Shared with Me',
        owner: 'farman.maiik@pwlive',
        size: '800MB',
        reason: 'Presentation slides for client meetings',
        clikkle: false,
        date: '25 Jul 2024'
    },
    {
        type: 'file',
        name: 'Upload solution',
        location: 'My Drive',
        owner: 'pwcontent@pwlive',
        size: '450KB',
        reason: 'Detailed budget allocation report',
        clikkle: false,
        date: '23 Jul 2024'
    },
    {
        type: 'folder',
        name: 'swift-track',
        location: 'Shared with Me',
        owner: 'shashwatos1003@gmail.com',
        size: '2GB',
        reason: 'Documents related to the upcoming launch',
        clikkle: false,
        date: '09 Aug 2024'
    },
    {
        type: 'file',
        name: 'Resources',
        location: 'My Drive',
        owner: 'farman.maiik@pwlive',
        size: '900MB',
        reason: 'Ideas for social media posts and campaigns',
        clikkle: false,
        date: '14 Oct 2023'
    },
    {
        type: 'file',
        name: 'Resources',
        location: 'My Drive',
        owner: 'farman.maiik@pwlive',
        size: '900MB',
        reason: 'Ideas for social media posts and campaigns',
        clikkle: false,
        date: '10 Oct 2023'
    },
    
];

export const RandomClikkleItems = [
    {
        type: "file",
        name: "Upload solution",
        location: "My Drive",
        owner: "pwcontent@pwlive",
        size: "1.2MB",
        reason: "Blueprint of system architecture",
        clikkle: false,
        date: '23 Jul 2024'
    },
    {
        type: "file",
        name: "Upload solution",
        location: "Shared with Me",
        owner: "farman.maiik@pwlive",
        size: "800KB",
        reason: "Detailed report on customer segmentation",
        clikkle: false,
        date: '17 Oct 2023'
    },
    {
        type: "folder",
        name: "Resources",
        location: "My Drive",
        owner: "farman.maiik@pwlive",
        size: "2GB",
        reason: "Monthly sales records and analytics",
        clikkle: false,
        date: '14 Oct 2023'
    },
    {
        type: "file",
        name: "swift-track",
        location: "Shared with Me",
        owner: "shashwatos1003@gmail.com",
        size: "300MB",
        reason: "Notes from UX research sessions",
        clikkle: false,
        date: '09 Aug 2024'
    },
    {
        type: "folder",
        name: "Resources",
        location: "My Drive",
        owner: "farman.maiik@pwlive",
        size: "1.8GB",
        reason: "UI and UX product mockups",
        clikkle: false,
        date: '10 Oct 2023'
    },
    {
        type: 'file',
        name: 'Project Proposal',
        location: 'Shared with Me',
        owner: 'alex.johnson@company.com',
        size: '1.2GB',
        reason: 'Proposal for the new marketing project',
        clikkle: false,
        date: '18 Sep 2023'
    },
    {
        type: 'folder',
        name: 'Training Materials',
        location: 'My Drive',
        owner: 'lisa.brown@education.org',
        size: '2.5GB',
        reason: 'Resources for employee training sessions',
        clikkle: false,
        date: '12 Aug 2024'
    },
    {
        type: 'file',
        name: 'Monthly Report',
        location: 'My Drive',
        owner: 'robert.miller@finance.com',
        size: '3MB',
        reason: 'Detailed financial report for last month',
        clikkle: false,
        date: '01 Oct 2023'
    },
    {
        type: 'folder',
        name: 'Team Collaboration',
        location: 'Shared with Me',
        owner: 'emily.clark@team.com',
        size: '500MB',
        reason: 'Documents for cross-team collaboration',
        clikkle: false,
        date: '25 Sep 2023'
    },
    {
        type: 'file',
        name: 'Marketing Strategy',
        location: 'My Drive',
        owner: 'michael.wilson@agency.com',
        size: '1.8GB',
        reason: 'Marketing plans and execution strategy',
        clikkle: false,
        date: '05 Jul 2024'
    },
    {
        type: 'folder',
        name: 'Conference Notes',
        location: 'Shared with Me',
        owner: 'sarah.james@events.com',
        size: '200MB',
        reason: 'Notes and presentations from the conference',
        clikkle: false,
        date: '15 Oct 2023'
    },
    {
        type: 'file',
        name: 'User Feedback',
        location: 'My Drive',
        owner: 'david.hall@support.com',
        size: '4MB',
        reason: 'Customer feedback for product improvement',
        clikkle: false,
        date: '29 Sep 2023'
    },
    {
        type: 'folder',
        name: 'Design Assets',
        location: 'My Drive',
        owner: 'olivia.moore@design.com',
        size: '3GB',
        reason: 'Graphic assets for the website redesign',
        clikkle: false,
        date: '21 Aug 2024'
    },

    
];

export const ClikkleItems = [
    {
        type: 'folder',
        name: 'Upload solution',
        location: 'My Drive',
        owner: 'shashwatos1003@gmail.com',
        size: '150MB',
        reason: 'Scripts and tools for CI/CD pipelines',
        clikkle: true,
        date: '09 Aug 2024'
    },
    {
        type: 'folder',
        name: 'swift-track',
        location: 'Shared with Me',
        owner: 'pwcontent@pwlive',
        size: '300MB',
        reason: 'Clikkle interface design assets',
        clikkle: true,
        date: '23 Jul 2024'
    },
    {
        type: 'file',
        name: 'Resources',
        location: 'My Drive',
        owner: 'farman.maiik@pwlive',
        size: '2MB',
        reason: 'Future roadmap and vision for Clikkle',
        clikkle: true,
        date: '14 Oct 2023'
    },
    {
        type: 'file',
        name: 'Upload solution',
        location: 'Shared with Me',
        owner: 'shashwatos1003@gmail.com',
        size: '1.5MB',
        reason: 'API documentation for internal use',
        clikkle: true,
        date: '09 Aug 2024'
    },
    {
        type: 'folder',
        name: 'Resources',
        location: 'My Drive',
        owner: 'farman.maiik@pwlive',
        size: '500MB',
        reason: 'Marketing materials and campaigns assets',
        clikkle: true,
        date: '10 Oct 2023'
    }
];
