import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";
import Catelog from "./FoldersList"
import { ClikkleItems } from "../Constants/DummyData";

const SuggestedFolder = () => {
  const [suggestedFolder, setSuggestedFolder] = useState(true);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
    >
      <Button
        startIcon={
          suggestedFolder ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
        }
        onClick={() => setSuggestedFolder(!suggestedFolder)}
        fullWidth
        sx={{ textAlign: "left", width: "auto" }}
      >
        Suggested Folder
      </Button>

      {suggestedFolder && <Catelog items={ClikkleItems} />}
    </Box>
  );
};

export default SuggestedFolder;
