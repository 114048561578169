import React, { useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Folder, AccountCircle, MoreVert, Star } from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Download from "@mui/icons-material/Download";
import Edit from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share"; // Changed to ShareIcon from the default Share
import Delete from "@mui/icons-material/Delete";
import Info from "@mui/icons-material/Info";
import ThumbDown from "@mui/icons-material/ThumbDown";
import PeopleIcon from "@mui/icons-material/People";
import { useTheme } from "../../style/theme";

const FavoriteList = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState(null); // For Menu anchor
  const [open, setOpen] = useState(false); // For controlling Menu visibility

  // Handle menu opening
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Handle menu closing
  const handleMenuClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const { mode } = useTheme();

  return (
    <Box display="flex" flexDirection="column" width="100%" gap="1rem">
      {items.map((item, i) => (
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          padding="0.2rem"
          key={i}
          sx={{
            borderBottom: "1px solid gray",
            position: "relative",
            "&:hover .action-icons": {
              visibility: "visible",
              opacity: "1",
            },
          }}
        >
          {/* File/Folder Name and Icon */}
          <Box width="75%" display="flex" alignItems="center" gap="1rem">
            {item.clikkle && item.type === "folder" && (
              <Folder style={{ fontSize: 20, color: "#488BF1" }} />
            )}
            {!item.clikkle && item.type === "folder" && (
              <Folder style={{ fontSize: 20, color: "#FFCA28" }} />
            )}

            {item.type === "file" && (
              <InsertDriveFileIcon style={{ fontSize: 25, color: "#488BF1" }} />
            )}
            <Tooltip title={item.name} placement="top">
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="700"
                lineHeight="2rem"
                // sx={{ color: "#FFF" }}
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                }}
              >
                {item.name}
              </Typography>
            </Tooltip>
          </Box>

          {/* Owner */}
          <Box width="52%" display="flex" alignItems="center" gap="1rem">
            <AccountCircle
              // style={{ fontSize: 50, color: "rgba(232, 238, 237, 0.8)" }}
              sx={{
                fontSize: "40px",
                color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
              }}
            />
            <Tooltip title={item.owner} placement="top">
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="700"
                lineHeight="2rem"
                // sx={{ color: "#FFF" }}
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                }}
              >
                {item.owner}
              </Typography>
            </Tooltip>
          </Box>

          {/* Last modified */}
          <Box width="38%" display="flex" alignItems="center" gap="1rem">
            <Tooltip title={item.date} placement="top">
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="700"
                lineHeight="2rem"
                // sx={{ color: "#FFF" }}
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                }}
              >
                {item.date}
              </Typography>
            </Tooltip>
          </Box>

          {/* File Size */}
          <Box width="20%" display="flex" alignItems="center" gap="1rem">
            <Tooltip title={item.size} placement="top">
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="700"
                lineHeight="2rem"
                // sx={{ color: "#FFF" }}
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                }}
              >
                {item.size}
              </Typography>
            </Tooltip>
          </Box>

          {/* File Location */}
          <Box width="65%">
            <Tooltip title={item.location} placement="top">
              <Typography
                variant="body"
                fontFamily="sans-serif"
                fontWeight="700"
                lineHeight="2rem"
                // sx={{ color: "#FFF" }}
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                }}
              >
                {item.location}
              </Typography>
            </Tooltip>
          </Box>

          {/* More actions menu */}
          <Box width="5%" display="flex" justifyContent="center">
            <Tooltip title="More actions (Alt+A)" placement="top">
              <MoreVert
                onClick={handleMenuOpen}
                // style={{ fontSize: 25, color: "#FFF", cursor: "pointer" }}
                sx={{
                  color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
                }}
              />
            </Tooltip>
            {/* Menu for the three dots */}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <OpenInNew fontSize="small" />
                </ListItemIcon>
                Open with
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <Download fontSize="small" />
                </ListItemIcon>
                Download
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <ShareIcon fontSize="small" /> {/* Changed to ShareIcon */}
                </ListItemIcon>
                Share
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                Rename
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <Info fontSize="small" />
                </ListItemIcon>
                Details
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                Move to Bin
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <ListItemIcon>
                  <ThumbDown fontSize="small" />
                </ListItemIcon>
                Not a Helpful Suggestion
              </MenuItem>
            </Menu>
          </Box>

          {/* Hoverable icons for Share, Download, Rename, and Star */}
          <Box
            className="action-icons"
            display="flex"
            justifyContent="center"
            gap="1rem"
            position="absolute"
            right="3%"
            sx={{
              visibility: "hidden",
              opacity: "0",
              transition: "visibility 0.2s, opacity 0.2s",
            }}
          >
            <Tooltip title="Share">
              <IconButton>
                <PeopleIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton>
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rename">
              <IconButton>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Star">
              <IconButton>
                <Star />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default FavoriteList ;
