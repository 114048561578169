import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTheme } from "../../style/theme";
const NotificationBar = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteForever = () => {
    alert("All items in the bin have been deleted forever!");
    setOpen(false);
  };

  const { mode } = useTheme();

  return (
    <>
      <Box
        sx={{
          color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
          backgroundColor: mode === "light" ? "white" : "#1E1E1E",
          border: `2px solid ${mode === "light" ? "#e5e7eb" : "transparent"}`,
          borderRadius: "0.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // backgroundColor: "rgba(220, 214, 214, 0.17)",
          padding: "10px 20px",
          // borderRadius: "8px",
          boxShadow: "0px 2px 4px rgba(187, 180, 180, 0.1)",
          margin: "20px 0",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            // color: "white",
            color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
          }}
        >
          Items in the bin will be deleted forever after 30 days
        </Typography>
        <Button
          variant="text"
          sx={{
            color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,

            fontSize: "15px",
            textTransform: "none",
            // color: "#1a73e8",
            fontWeight: "bold",
          }}
          onClick={handleClickOpen}
          startIcon={<DeleteOutlinedIcon />}
        >
          Empty bin
        </Button>
      </Box>

      {/* Dialog for confirmation */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete forever?</DialogTitle>
        <DialogContent>
          <Typography>
            All items in the bin will be deleted forever, and you won't be able
            to restore them.{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleDeleteForever} color="error">
            Delete forever
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationBar;
