import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './utilities/axios';

//pages
import Header from './components/Header';
import Home from './pages/Home';
import MyFiles from './pages/MyFiles';
import Recent from './pages/Recent';
import Favorite from './pages/Favorite';
import Trash from './pages/Trash';
import SharedWithMe from './pages/SharedWithMe';
import SharedByMe from './pages/SharedByMe';

const App = () => {
    return (
        <Header>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/myfiles' element={<MyFiles />} />
                <Route path='/recent' element={<Recent />} />
                <Route path='/favorite' element={<Favorite />} />
                <Route path='/trash' element={<Trash />} />
                <Route path='/shared-with-me' element={<SharedWithMe />} />
                <Route path='/shared-by-me' element={<SharedByMe />} />
            </Routes>
        </Header>
    );
};

export default App;