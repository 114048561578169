import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import TrashTypeDropdown from "../components/TrashDropdowns/TypeDropdown";
import TrashModifiedDropdown from "../components/TrashDropdowns/ModifiedDropdown";
import TrashSourceDropdown from "../components/TrashDropdowns/SourceDropdown";
import TrashView from "../components/Trash/TrashFilesAndFoldersView";
import NotificationBar from "../components/Trash/NotificationBar";
import FloatingActionButton from "../components/ActionButton/FloatingActionButton";
import { useTheme } from "../style/theme";

const Trash = () => {
    const { mode } = useTheme();
  
  return (
    <Box p={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap="1rem"
          alignItems="flex-start"
        >
          {/* Title aligned to the left */}
          <Typography
            fontSize="2rem"
            sx={{
              color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,

              fontWeight: "bold",
              // color: "#f5f5f5",
              padding: "0.5rem 1rem",
            }}
           
          >
            Trash
          </Typography>

          <Box display="flex" gap="1rem" justifyContent="center">
            {/*  Dropdown Components */}

            <TrashTypeDropdown />

            <TrashModifiedDropdown />

            <TrashSourceDropdown />

            {/* Action Button */}
            <FloatingActionButton />
          </Box>
        </Box>
      </Grid>
      <NotificationBar />
      <TrashView />
    </Box>
  );
};

export default Trash;
