import React, { useState } from "react";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import GridViewIcon from "@mui/icons-material/GridView";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PersonIcon from "@mui/icons-material/Person";
import FolderIcon from "@mui/icons-material/Folder";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; 
import { RandomClikkleItems } from "../Constants/DummyDataForSharedByMe";
import { MoreVert } from "@mui/icons-material";
import SharedByMeList from "./FilesAndFoldersList";
import SharedByMeGridView from "./GridViewSharedByMe";

const SharedByMeView = () => {
  const [view, setView] = useState("list");

  const handleViewChange = (event, newView) => {
    if (newView !== null) setView(newView);
  };

  return (
    <Box
      display="absolute"
      justifyContent="space-between"
      width="100%"
      alignItems="center"
    >
      {/* File View Sections */}
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="end"
          alignItems="end"
          width="100%"
          gap="1rem"
        >
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view selection"
          >
            <ToggleButton
              value="list"
              aria-label="list view"
              sx={{
                color: "#3767B1",
                paddingX: "1rem",
                border: "2px solid #3767B1",
                borderRadius: "20rem",
                "&.Mui-selected": {
                  color: "white",
                  backgroundColor: "#3767B1",
                },
              }}
            >
              <ListIcon fontSize="1rem" />
            </ToggleButton>
            <ToggleButton
              value="folder"
              aria-label="folder view"
              sx={{
                color: "#3767B1",
                paddingX: "1rem",
                border: "2px solid rgb(57, 108, 185)",
                borderRadius: "20rem",
                "&.Mui-selected": {
                  color: "white",
                  backgroundColor: "#3767B1",
                },
              }}
            >
              {view === "folder" ? (
                <GridViewRoundedIcon fontSize="1rem" />
              ) : (
                <GridViewIcon fontSize="1rem" />
              )}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {/*  List View */}
      {view === "list" && (
        <Box
          display="flex"
          width="100%"
          sx={{ maxHeight: "calc(100vh - 20rem)", overflowY: "auto" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            sx={{
              width: "100%",
              maxHeight: "82vh",
              overflowY: "auto",
              paddingTop: "1rem",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {/* Header Row with Icons */}
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              paddingY="1rem"
              sx={{ color: "#646464", borderBottom: "1px solid #646464" }}
            >
              <Box width="72%" display="flex" alignItems="center" gap="0.5rem">
                <FolderIcon fontSize="small" />
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                >
                  Name
                </Typography>
              </Box>
              <Box width="23%" display="flex" alignItems="center" gap="0.5rem">
                <PersonIcon fontSize="small" />
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                >
                  Shared to
                </Typography>
              </Box>
              <Box width="29%" display="flex" alignItems="center" gap="0.5rem">
                <AccessTimeIcon fontSize="small" /> {/* Updated icon for Last modified */}
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                >
                  Shared date
                </Typography>
              </Box>
              
              
              <Box width="4.5%" display="flex" alignItems="center" gap="0.5rem">
                <Typography
                  variant="body"
                  fontFamily="sans-serif"
                  fontWeight="700"
                />
                {/* Adding the three dots icon */}
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MoreVert />
                </Box>
              </Box>
            </Box>
            {/*  Files List */}
            <SharedByMeList items={RandomClikkleItems} />
          </Box>
        </Box>
      )}

      {/*  Grid View */}
      {view === "folder" && (
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          sx={{
            width: "100%",
            paddingTop: "1rem",
            maxHeight: "calc(100vh - 21rem)",
            overflowY: "auto",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {/*  Grid View */}
          <SharedByMeGridView items={RandomClikkleItems} />
        </Box>
      )}
    </Box>
  );
};

export default SharedByMeView;
