import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import MyFilesTypeDropdown from "../components/MyFilesDropdown/TypeDropdown";
import MyFilesPeopleDropdown from "../components/MyFilesDropdown/PeopleDropdown";
import MyFilesModifiedDropdown from "../components/MyFilesDropdown/ModifiedDropdown";
import SourceDropdown from "../components/MyFilesDropdown/SourceDropdown";
import MyFilesView from "../components/MyFiles/MyFilesFoldersView";
import FloatingActionButton from "../components/ActionButton/FloatingActionButton";
import { useTheme } from "../style/theme";

const MyFiles = () => {
  const { mode } = useTheme();
  return (
    <Box p={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap="1rem"
          alignItems="flex-start"
        >
          {/* Title aligned to the left */}
          <Typography
            fontSize="2rem"
            sx={{
              color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,

              fontWeight: "bold",
              // color: "#f5f5f5",
              padding: "0.5rem 1rem",
            }}
          >
            My Files
          </Typography>

          <Box display="flex" gap="1rem" justifyContent="center">
            {/*  Dropdown Components */}
            <MyFilesTypeDropdown />

            <MyFilesPeopleDropdown />

            <MyFilesModifiedDropdown />

            <SourceDropdown />

            {/* ActionButton */}
            <FloatingActionButton />
          </Box>
        </Box>
      </Grid>

      <MyFilesView />
    </Box>
  );
};

export default MyFiles;
