import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TypeDropdown from "../components/DropdownData/TypeDropdownData";
import PeopleDropdown from "../components/DropdownData/PeopleDropdownData";
import ModifiedDropdown from "../components/DropdownData/ModifiedDropdownData";
import LocationDropdown from "../components/DropdownData/LocationDropdownData";
import FloatingActionButton from "../components/ActionButton/FloatingActionButton";
import SuggestedFolder from "../components/SuggestFolders/SuggestedFolder";
import SuggestedFile from "../components/SuggestedFiles/SuggestedFile";
import { useTheme } from "../style/theme";

const Home = () => {
  const [selectedPerson, setSelectedPerson] = useState("");

  const { mode } = useTheme();

  return (
    <Box p={2}>
      <Grid container alignItems="center" justifyContent="center" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap="1rem"
          alignItems="center"
        >
          <Typography
            fontSize="2rem"
            //  sx={{ fontWeight: "bold", color: "#f5f5f5", padding: "0.5rem 1rem" }}>
            sx={{
              color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,
            }}
          >
            Welcome to Clikkle File
          </Typography>

          <Box display="flex" gap="1rem" justifyContent="center">
            {/* Type Dropdown Component */}
            <TypeDropdown />

            {/* People Dropdown Component */}
            <PeopleDropdown
              selectedPerson={selectedPerson}
              setSelectedPerson={setSelectedPerson}
            />

            {/* Modify dropdown */}
            <ModifiedDropdown
              selectedPerson={selectedPerson}
              setSelectedPerson={setSelectedPerson}
            />

            {/* Location dropdown */}
            <LocationDropdown
              selectedPerson={selectedPerson}
              setSelectedPerson={setSelectedPerson}
            />
          </Box>
        </Box>
      </Grid>

      {/* Suggested Folder Component */}
      <SuggestedFolder />

      {/* Suggested File Component */}
      <SuggestedFile />

      {/* Floating Action Button */}
      <FloatingActionButton />
    </Box>
  );
};

export default Home;
