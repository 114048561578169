import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SharedByMeTypeDropdown from "../components/SharedByMeDropdowns/TypeDropdown";
import SharedByMePeopleDropdown from "../components/SharedWithMeDropdowns/PeopleDropdown";
import SharedByMeModifiedDropdown from "../components/SharedByMeDropdowns/ModifiedDropdown";
import SharedByMeSourceDropdown from "../components/SharedByMeDropdowns/SourceDropdown";
import SharedByMeView from "../components/SharedByMe/FilesAndFolderView";
import FloatingActionButton from "../components/ActionButton/FloatingActionButton";
const SharedByMe = () => {
  return (
    <Box p={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap="1rem"
          alignItems="flex-start"
        >
          {/* Title aligned to the left */}
          <Typography
            fontSize="2rem"
            sx={{
              fontWeight: "bold",
              color: "#f5f5f5",
              padding: "0.5rem 1rem",
            }}
          >
            Shared by me
          </Typography>

          <Box display="flex" gap="1rem" justifyContent="center">
          <SharedByMeTypeDropdown />
          <SharedByMePeopleDropdown />
          <SharedByMeModifiedDropdown />
          <SharedByMeSourceDropdown />


          {/* Action Button */}
          <FloatingActionButton />
          </Box>
        </Box>
      </Grid>

      <SharedByMeView />
    </Box>
  );
};

export default SharedByMe;
