import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SharedWithMeModifiedDropdown from "../components/SharedWithMeDropdowns/ModifiedDropdown";
import SharedWithMeTypeDropdown from "../components/SharedWithMeDropdowns/TypeDropdown";
import SharedWithMePeopleDropdown from "../components/SharedWithMeDropdowns/PeopleDropdown";
import SharedWithMeSourceDropdown from "../components/SharedWithMeDropdowns/SourceDropdown";
import SharedWithMeView from "../components/SharedWithMe/FilesAndFoldersView";
import FloatingActionButton from "../components/ActionButton/FloatingActionButton";
import { useTheme } from "../style/theme";

const SharedWithMe = () => {
      const { mode } = useTheme();
  
  return (
    <Box p={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          gap="1rem"
          alignItems="flex-start"
        >
          {/* Title aligned to the left */}
          <Typography
            fontSize="2rem"
            sx={{
              color: `${mode === "light" ? "rgba(0, 0, 0, 0.6)" : "#fff"} `,

              fontWeight: "bold",
              // color: "#f5f5f5",
              padding: "0.5rem 1rem",
            }}
          >
            Shared with me
          </Typography>

          <Box display="flex" gap="1rem" justifyContent="center">

            {/*  Dropdown Components */}
            <SharedWithMeTypeDropdown />
            <SharedWithMePeopleDropdown />
            <SharedWithMeModifiedDropdown />
            <SharedWithMeSourceDropdown />

            {/* Action button */}
            <FloatingActionButton />
          </Box>
        </Box>
      </Grid>

      <SharedWithMeView />
    </Box>
  );
};

export default SharedWithMe;
