// import React, { useState } from "react";
// import {
//   Box,
//   Fab,
//   Menu,
//   MenuItem,
//   ListItemIcon,
//   ListItemText,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
// import DescriptionIcon from "@mui/icons-material/Description";
// import TableChartIcon from "@mui/icons-material/TableChart";
// import SlideshowIcon from "@mui/icons-material/Slideshow";
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

// const FloatingActionButton = () => {
//   const [isActive, setIsActive] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleClick = (event) => {
//     setIsActive((prev) => !prev);
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setIsActive((prev) => !prev);
//     setAnchorEl(null);
//   };

//   return (
//     <Box
//       style={{
//         position: "fixed",
//         bottom: "20px",
//         right: "20px",
//         marginRight: "50px",
//         marginBottom: "10px",
//       }}
//     >
//       <Fab
//         color="primary"
//         aria-label="add"
//         onClick={handleClick}
//         sx={{
//           transition: "transform 0.3s ease",
//           "& .MuiSvgIcon-root": {
//             transition: "transform 0.3s ease",
//             transform: isActive ? "rotate(45deg)" : "rotate(0deg)",
//           },
//         }}
//       >
//         <AddIcon />
//       </Fab>

//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: "top", horizontal: "right" }}
//         transformOrigin={{ vertical: "bottom", horizontal: "right" }}
//       >
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <CreateNewFolderIcon fontSize="small" sx={{ color: "#3379C7" }} />
//           </ListItemIcon>
//           <ListItemText>New Folder</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <CloudUploadIcon fontSize="small" sx={{ color: "#3379C7" }} />
//           </ListItemIcon>
//           <ListItemText>File Upload</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <InsertDriveFileIcon fontSize="small" sx={{ color: "#3379C7" }} />
//           </ListItemIcon>
//           <ListItemText>Folder Upload</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <DescriptionIcon fontSize="small" sx={{ color: "#3379C7" }} />
//           </ListItemIcon>
//           <ListItemText>Google Docs</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <TableChartIcon fontSize="small" sx={{ color: "#3379C7" }} />
//           </ListItemIcon>
//           <ListItemText>Google Sheets</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <SlideshowIcon fontSize="small" sx={{ color: "#3379C7" }} />
//           </ListItemIcon>
//           <ListItemText>Google Slides</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleClose}>
//           <ListItemIcon>
//             <FormatListBulletedIcon fontSize="small" sx={{ color: "#3379C7" }} />
//           </ListItemIcon>
//           <ListItemText>Google Forms</ListItemText>
//         </MenuItem>
//       </Menu>
//     </Box>
//   );
// };

// export default FloatingActionButton;


import React, { useState } from "react";
import {
  Box,
  Fab,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DescriptionIcon from "@mui/icons-material/Description";
import TableChartIcon from "@mui/icons-material/TableChart";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const FloatingActionButton = () => {
  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setIsActive((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIsActive((prev) => !prev);
    setAnchorEl(null);
  };

  return (
    <Box
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        marginRight: "50px",
        marginBottom: "10px",
      }}
    >
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleClick}
        sx={{
          transition: "transform 0.3s ease",
          "& .MuiSvgIcon-root": {
            transition: "transform 0.3s ease",
            transform: isActive ? "rotate(45deg)" : "rotate(0deg)",
          },
        }}
      >
        <AddIcon />
      </Fab>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <CreateNewFolderIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>New Folder</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <CloudUploadIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>File Upload</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <InsertDriveFileIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>Folder Upload</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DescriptionIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>Google Docs</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <TableChartIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>Google Sheets</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <SlideshowIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>Google Slides</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <FormatListBulletedIcon fontSize="small" sx={{ color: "#3379C7" }} />
          </ListItemIcon>
          <ListItemText>Google Forms</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          More
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default FloatingActionButton;
