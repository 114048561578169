import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline"; 
import MeetIcon from "@mui/icons-material/VideoCall"; 

const SourceDropdown = () => {
  return (
    <FormControl sx={{ width: "10rem" }}>
      <InputLabel id="source-dialog">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <MailOutlineIcon fontSize="small" />
          Source
        </Box>
      </InputLabel>
      <Select labelId="source-dialog" label="Source" sx={{ borderRadius: "20rem" }}>
        <MenuItem value={10}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <MailOutlineIcon sx={{ color: "#D93025" }} fontSize="small" /> 
            Gmail
          </Box>
        </MenuItem>
        <MenuItem value={20}>
          <Box display="flex" alignItems="center" gap="0.5rem">
            <MeetIcon sx={{ color: "#34b7f1" }} fontSize="small" /> 
            Meet
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SourceDropdown;
