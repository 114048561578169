export const NonClikkleItems = [
    {
        type: 'folder',
        name: 'Research Papers',
        location: 'My Drive',
        owner: 'Me',
        size: '1GB',
        reason: 'Contains academic research papers',
        clikkle: false,
        date: '20 Oct 2024'
    },
    {
        type: 'folder',
        name: 'Client Presentations',
        location: 'Shared with Me',
        owner: 'Me',
        size: '800MB',
        reason: 'Presentation slides for client meetings',
        clikkle: false,
        date: '12 Sep 2024'
    },
    {
        type: 'file',
        name: 'Budget Report',
        location: 'My Drive',
        owner: 'Me',
        size: '450KB',
        reason: 'Detailed budget allocation report',
        clikkle: false,
        date: '15 Aug 2024'
    },
    {
        type: 'folder',
        name: 'Product Launch Plans',
        location: 'Shared with Me',
        owner: 'Me',
        size: '2GB',
        reason: 'Documents related to the upcoming launch',
        clikkle: false,
        date: '30 Jul 2024'
    },
    {
        type: 'folder',
        name: 'Legal Documents',
        location: 'My Drive',
        owner: 'Me',
        size: '750MB',
        reason: 'Legal agreements and contracts',
        clikkle: false,
        date: '25 Jun 2024'
    },
    {
        type: 'file',
        name: 'Annual Report 2023',
        location: 'Shared with Me',
        owner: 'Me',
        size: '2.5MB',
        reason: 'Company annual report for 2023',
        clikkle: false,
        date: '18 May 2024'
    },
    {
        type: 'folder',
        name: 'Marketing Strategy 2024',
        location: 'My Drive',
        owner: 'Me',
        size: '1.2GB',
        reason: 'Strategic plan for marketing in 2024',
        clikkle: false,
        date: '10 Apr 2024'
    },
    {
        type: 'folder',
        name: 'HR Policies',
        location: 'Shared with Me',
        owner: 'Me',
        size: '300MB',
        reason: 'Human resources policies and guidelines',
        clikkle: false,
        date: '25 Mar 2024'
    },
    {
        type: 'file',
        name: 'Employee Benefits',
        location: 'My Drive',
        owner: 'Me',
        size: '450KB',
        reason: 'List of employee benefits and perks',
        clikkle: false,
        date: '18 Feb 2024'
    },
    {
        type: 'folder',
        name: 'Customer Feedback',
        location: 'Shared with Me',
        owner: 'Me',
        size: '1GB',
        reason: 'Compilation of customer feedback surveys',
        clikkle: false,
        date: '30 Jan 2024'
    }
];

export const RandomClikkleItems = [
    {
        type: "file",
        name: "Employee Handbook",
        location: "My Drive",
        owner: "Me",
        size: "300KB",
        reason: "Guide for employee policies",
        clikkle: false,
        date: '10 May 2024'
    },
    {
        type: "file",
        name: "Quarterly Review",
        location: "Shared with Me",
        owner: "Me",
        size: "550KB",
        reason: "Review of Q2 performance",
        clikkle: false,
        date: '15 Apr 2024'
    },
    {
        type: "file",
        name: "System Architecture",
        location: "My Drive",
        owner: "Me",
        size: "1.2MB",
        reason: "Blueprint of system architecture",
        clikkle: false,
        date: '20 Mar 2024'
    },
    {
        type: "file",
        name: "User Feedback",
        location: "Shared with Me",
        owner: "Me",
        size: "600KB",
        reason: "Compiled user feedback data",
        clikkle: false,
        date: '18 Feb 2024'
    },
    {
        type: "file",
        name: "Employee Payroll",
        location: "My Drive",
        owner: "Me",
        size: "500KB",
        reason: "Monthly payroll data",
        clikkle: false,
        date: '25 Jan 2024'
    },
    {
        type: "file",
        name: "Marketing Proposal",
        location: "Shared with Me",
        owner: "Me",
        size: "1MB",
        reason: "Proposal for next marketing campaign",
        clikkle: false,
        date: '15 Dec 2023'
    },
    {
        type: "folder",
        name: "IT Infrastructure Docs",
        location: "My Drive",
        owner: "Me",
        size: "2GB",
        reason: "Documentation for IT infrastructure",
        clikkle: false,
        date: '05 Nov 2023'
    },
    {
        type: "folder",
        name: "Database Optimization Guides",
        location: "/it/database",
        owner: "Me",
        size: "1.5GB",
        reason: "Guides on optimizing database performance",
        clikkle: false,
        date: '12 Oct 2023'
    },
    {
        type: "folder",
        name: "Cloud Migration Plan",
        location: "/it/cloud",
        owner: "Me",
        size: "3GB",
        reason: "Detailed plan for migrating to the cloud",
        clikkle: false,
        date: '20 Sep 2023'
    },
    {
        type: "file",
        name: "Employee Training Materials",
        location: "Shared with Me",
        owner: "Me",
        size: "1.5MB",
        reason: "Training material for employees",
        clikkle: false,
        date: '28 Oct 2023'
    },
    {
        type: "folder",
        name: "Project Roadmaps",
        location: "My Drive",
        owner: "Me",
        size: "3GB",
        reason: "Roadmaps for ongoing projects",
        clikkle: false,
        date: '14 Sep 2023'
    },
    {
        type: "file",
        name: "Employee Satisfaction",
        location: "Shared with Me",
        owner: "Me",
        size: "800KB",
        reason: "Survey to gauge employee satisfaction",
        clikkle: false,
        date: '10 Aug 2023'
    }
];

export const ClikkleItems = [
    {
        type: 'folder',
        name: 'Clikkle DevOps',
        location: 'My Drive',
        owner: 'Me',
        size: '150MB',
        reason: 'Scripts and tools for CI/CD pipelines',
        clikkle: true,
        date: '10 Jan 2024'
    },
    {
        type: 'folder',
        name: 'Clikkle UI Designs',
        location: 'Shared with Me',
        owner: 'Me',
        size: '300MB',
        reason: 'Clikkle interface design assets',
        clikkle: true,
        date: '05 Dec 2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Content Strategy',
        location: 'My Drive',
        owner: 'Me',
        size: '200MB',
        reason: 'Content plans and drafts for campaigns',
        clikkle: true,
        date: '15 Nov 2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Server Configurations',
        location: 'Shared with Me',
        owner: 'Me',
        size: '100MB',
        reason: 'Configuration files for servers',
        clikkle: true,
        date: '01 Oct 2023'
    },
    {
        type: 'file',
        name: 'Clikkle Vision Document',
        location: 'My Drive',
        owner: 'Me',
        size: '1MB',
        reason: 'Future roadmap and vision for Clikkle',
        clikkle: true,
        date: '10 Sep 2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Marketing Campaigns',
        location: 'Shared with Me',
        owner: 'Me',
        size: '500MB',
        reason: 'Marketing materials and campaigns assets',
        clikkle: true,
        date: '22 Aug 2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Customer Support',
        location: 'My Drive',
        owner: 'Me',
        size: '350MB',
        reason: 'Support documentation and guides',
        clikkle: true,
        date: '18 Jul 2023'
    },
    {
        type: 'file',
        name: 'Clikkle Launch Presentation',
        location: 'Shared with Me',
        owner: 'Me',
        size: '2.5MB',
        reason: 'Presentation for Clikkle launch event',
        clikkle: true,
        date: '12 Jun 2023'
    },
    {
        type: 'file',
        name: 'Clikkle Quarterly Review',
        location: 'My Drive',
        owner: 'Me',
        size: '1.8MB',
        reason: 'Quarterly review document for Q1 2023',
        clikkle: true,
        date: '25 May 2023'
    },
    {
        type: 'folder',
        name: 'Clikkle Product Specifications',
        location: 'Shared with Me',
        owner: 'Me',
        size: '900MB',
        reason: 'Product specifications for upcoming release',
        clikkle: true,
        date: '30 Apr 2023'
    }
];
